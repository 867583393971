
let abbreviation = ''
let name = ''
let zip1 = ''
let zip2 = ''
let address1 = ''
let address2 = ''
let address3 = ''
let tel1 = ''
let tel2 = ''
let tel3 = ''
let fax1 = ''
let fax2 = ''
let fax3 = ''
let detailsHtml = null
const UnusableCharacters = [
  '①','②','③','④','⑤','⑥','⑦','⑧','⑨','⑩','⑪','⑫','⑬','⑭','⑮','⑯','⑰','⑱','⑲','⑳',
  'Ⅰ','Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ', 'Ⅵ', 'Ⅶ', 'Ⅷ', 'Ⅸ', 'Ⅹ', 'ⅰ', 'ⅱ', 'ⅲ', 'ⅳ', 'ⅴ', 'ⅵ', 'ⅶ', 'ⅷ', 'ⅸ', 'ⅹ',
  '㎜', '㎝', '㎞', '㎎', '㎏','㏄', '㍉','㌔', '㌢', '㍍', '㌘', '㌧', '㌃', '㌶', '㍑', '㍗', '㌍', '㌦', '㌣', '㌫',
  '№', '℡', '№', '㏍', '℡' ,'㊤', '㊥', '㊦', '㊧','㊨','㈱', '㈲','㈹', '㍾', '㍽', '㍼', '㍻', '≡', '∑', '∫',
  '纊', '鍈', '蓜', '炻', '棈', '兊', '夋', '奛', '奣', '寬', '﨑', '嵂', '咊', '咩', '哿', '喆', '坙', '坥', '垬', '埈'
]
const MaintenancePath = '/stock_maintenance'

$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/quotation_order\//)){
    if(document.location.pathname.match(/^\/quotation_order\/new/)){
      restoreBeforeHtml()
      setJpostal()
      setQuotationDetailsActions($('.quotation_detail_table_item'))
    }
    if(document.location.pathname.match(/^\/quotation_order\/confirm/)){
      $('#confirm-form').on('submit', function(e){
        e.preventDefault()
        $('#cofirme-order').prop('disabled', true)
        this.submit()
      })
    }
    if(document.location.pathname.match(/^\/quotation_order\/complete/)){
      var beforeHtml = localStorage.getItem('beforeHtml')
      if (beforeHtml !== null){
        localStorage.removeItem('beforeHtml')
      }
    }

    // カレンダー
    $('.calender').daterangepicker({
      minDate: moment().startOf('day'),
      startDate: moment().startOf('day'),
      endDate: moment().startOf('day'),
      singleDatePicker: true,
      autoUpdateInput: false
    });
    $('.calender').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD'));
        setValue($(this))
        changeConfirmButtonDisabled()
        checkDate(this)
    });

    function checkDate(target){
      const format = /^\d{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])$/;
      var date = new Date($(target).val())
      if (isNaN(date)){
        addErrorMessage($(target), '正しい日付を入力してください')
        return;
      }else if (!format.test($(target).val())) {
        addErrorMessage($(target), '正しい形式の日付を入力してください')
        return;
      }
      var date = new Date($(target).val())
      var today = new Date()
      today.setHours(0, 0, 0, 0);

      if (date < today) {
        addErrorMessage($(target), '過去の日付は入力できません')
      }else{
        removeErrorMessage($(target))
      }
    }

    // 各種ボタン押下時のメンテナンス判定
    function get_maintenance_status(){
      var status = false
      $.ajax({
        type: 'GET',
        url: `/quotation_order/maintenance_status`,
        async: false
      }).done(function(json){
        status = json.is_maintenance
      }).fail(function(){
        status = false
      })
      return status
    }

    function initForm(){
      // 特記事項の追加ボタンを押下した時
      $('#add_note').on('click', function(e){
        e.preventDefault()
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
          return;
        }

        addNote()
      })

      // 納入先を選択した時
      // 納入先を編集ボタンを押下した時
      initDeliveryDestinationEvent()

      // 概要のフォームバリデーション
      setOverViewFormActions()
      // 納入先のラジオボタンを選択した時
      $('input:radio[name="new_or_existing"]').on('change', function(e){
        deliveryDistinationRadioEvent(e.target)
      })

      // 納入先を新規作成ボタンを押下した時
      $('#save-new-delivery-destination').on('click', function(e){
        e.preventDefault()
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
          return;
        }

        saveDeliveryDestination('納入先を登録しました')
      })

      // 納入先を編集保存ボタンを押下した時
      $('#save-existing-delivery-destination').on('click', function(e){
        e.preventDefault()
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
          return;
        }

        saveDeliveryDestination('納入先を更新しました')
      })

      if ($('#delivery-destination-select').val() === '') {
        $('#edit-delivery-distination').prop('disabled', true)
        $('#edit-delivery-distination').hide()
        $('span.zip_address_text').hide()
      }

      $('#chancel-existing-delivery-destination').on('click', function(e){
        e.preventDefault()
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
          return;
        }

        chancelEditDeliveryDestinationEvent()
      })

      $('#search_stock').on('click', function(e){
        e.preventDefault()
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
          return;
        }

        requestStockSearch()
      })

      $('.search-item-form-clear').on('click', function(e){
        e.preventDefault()
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
          return;
        }

        itemSearchFealdClear()
        changeSearchStockButtonDisabled()
        removeErrorMessage($('.length-text'))
      })

      $('.length-text').on('keyup change', function(e){
        changeSearchStockButtonDisabled()
      })

      $('.add-quotation-detail').on('click', function(e){
        e.preventDefault()
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
          return;
        }

        addQuotationDetail()
      })
      $('#check_all').on('change', function(e){
        allChangeQuotationDetailCheck($(e.target).prop('checked'))
      })
      $('#delete-selected-detail').on('click', function(e){
        e.preventDefault()
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
          return;
        }

        deleteSelectedDetails()
      })
      $('#back-to-input').on('click', function(e){
        e.preventDefault()
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
          return;
        }

        var currentUrl = window.location.href;

        var newUrl = currentUrl.replace(/confirm/g, 'new');
        window.location.href = newUrl;
      })
      $('#confirm_button').on('click', function(e){
        localStorage.setItem('beforeHtml', $('.content').html())
      })
      $('.length-text').on('keyup change', function(e){
        validateNumericLengthForm($(e.target), 6)
        changeSearchStockButtonDisabled()
        setMaintenanceToolTip()
      })
      $('.length-select').on('change', function(e){
        setMaintenanceToolTip()
      })
      $('size-full-select').on('change', function(e){
        setMaintenanceToolTip()
      })
      $('#pipe_num').on('keyup change', function(e){
        validateForm($(e.target), 6)
        changeSearchStockButtonDisabled()
        setMaintenanceToolTip()
      })


      $('#with_milsheet').on('change',function(e){
        $(e.target).attr('checked', $(e.target).prop('checked'))
      })

      $('#check_all').on('change', function(e){
        changeDeleteDetailsButtonDisabled()
      })

      $('#stock-list').find('.select-steel-pipe').on('click', function(e){
        e.preventDefault()
        selectListSteelPipe($(e.target))
      })

      $('.open-modal').on('click', function(){
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
        }
      })

      createDetailTableObserver()

      setRequireFormEvent(document)

      changeConfirmButtonDisabled()
      changeDeleteDetailsButtonDisabled()
      changeStatusDeliveryDistinationForm('hide')
      defineSizefullSelectDetail();
    }

    // ツールチップの初期化
    function initToolTips(){
      $('.item-history-button').each(function(index, element){
        setToolTipOption(element)

        $(element).on('click', function(e){
          if (get_maintenance_status()){
            window.location.href = MaintenancePath;
            return;
          }

          $('#selected-steel-pipe-type').val($(element).data('steel-pipe-type'))
          $('#selected-steel-pipe-cuttable').val($(element).data('steel-pipe-cuttable'))
          setSteelPipeFormForToolTips($(e.target))
          $(e.target).tooltip('hide');
        })
      })
    }

    function setToolTipOption(element){
      $(element).tooltip({
        title: `品名規格名：${$(element).data('item-name')}<br>寸法：${$(element).data('size-full')}<br>長さ：${$(element).data('length')}`,
        html: true,
        template: '<div class="tooltip" role="tooltip" style="width: 200px;"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
      })
    }

    // ツールチップ選択時の寸法・長さ選択肢セット
    function setSteelPipeFormForToolTips(target){
      $.ajax({
        url: '/stocks/steel_pipe',
        type: 'post',
        data: {
          steel_pipe_type: target.data('steel-pipe-type'),
          item_name_text: target.data('item-name')
        },
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          $('.item-name').html(target.data('item-name'))
          $('.item-name-hidden').val(target.data('item-name'))

          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            setSteelPipeItem(json)
          }

          // 寸法設定
          $('.size-full-select').val(target.data('size-full')).trigger('change')

          // 長さ設定(テキストの場合のみ)
          if($('#selected-steel-pipe-cuttable').val() == 'true'){
            $('.length-text-form').append(`<input type="hidden" id="selected_text_length_val" value=${target.data('length')}>`)
          }else {
            $('.length-select-form').append(`<input type="hidden" id="selected_length_val" value=${target.data('length')}>`)
          }

          changeSearchStockButtonDisabled()
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });
    }

    function setMaintenanceToolTip(){
      if ($('#maintenance').val() === 'false' ){
        $('#search_stock').tooltip('destroy')
        changeSearchStockButtonDisabled()
        $('.update-quotation-detail').prop('disabled', false)
        $('.update-quotation-detail').each(function(index, element){
          $(element).tooltip('destroy')
        })
        changeUpdateCopyButtonDisabled();
        return;
      }

      title = 'システムメンテナンス中のため<br>現在の時間はご使用いただけ<br>ません。'
      $('#search_stock').prop('disabled', true)
      $('#search_stock').tooltip({
        title: title,
        html: true,
        template: '<div class="tooltip" role="tooltip" style="width: 250px;"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
      })

      $('.update-quotation-detail').prop('disabled', true)
      $('.update-quotation-detail').each(function(index, element){
        $(element).tooltip({
          title: title,
          html: true,
          template: '<div class="tooltip" role="tooltip" style="width: 250px;"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        })
      })
    }

    function getMaintenanceStatus(){
      $.ajax({
        url: `/quotation_order/maintenance_status`,
        success: function(json, status, xhr) {
          $('#maintenance').val(json.is_maintenance.toString())
          setMaintenanceToolTip()
        },
        error: function(xhr, status, e){
          alert(status);
        }
      });
    }

    function setRequireFormEvent(target){
      $(target).find('.require').each(function(index,element){
        $(element).on('keyup change', function(e){
          changeConfirmButtonDisabled()
        })
      })
    }

    function changeConfirmButtonDisabled(){
      var is_empty_form = false
      var is_empty_detail = false
      var has_error = false
      var is_editing = $('#is_editing').val() === 'true'

      $('.require').each(function(index, element){
        if ($(element).val() === '') { is_empty_form = true }
      })
      $('#quotation_detail_table_body').find('.length').each(function(index, element){
        if ($(element).val() === '') { is_empty_form = true }
      })
      $('#quotation_detail_table_body').find('.pipe-num').each(function(index, element){
        if ($(element).val() === '') { is_empty_form = true }
      })
      if ($('.quotation_detail_table_item').length === 0) { is_empty_detail = true }
      $('input').each(function(index, element){
        if ($(element).parent('.has-error').length > 0) { has_error = true }
      })

      if (is_empty_form || has_error || is_empty_detail || is_editing) {
        $('#confirm_button').prop('disabled', true)
      }else{
        $('#confirm_button').prop('disabled', false)
      }
    }

    function setAccordionIconAnimation(){
      $(".accordion-button").click(function(){
        if($(this).hasClass("collapsed")){
          $(this).find('i').removeClass('fa-chevron-right').addClass('fa-chevron-down');
        }else{
          $(this).find('i').removeClass('fa-chevron-down').addClass('fa-chevron-right');
        }
      });
    }

    function restoreBeforeHtml(){
      var beforeHtml = localStorage.getItem('beforeHtml')
      if (beforeHtml !== null){
        $('.content').html(beforeHtml)
        initSelect2()
        // initQuotationDetailForm()
        localStorage.removeItem('beforeHtml')
      }
    }

    function initSelect2(){
      $('span.select2').remove()

      $('.size-full-select').html([])
      $('.length-select').html([])
      $('.steel-pipe-name-select').html([])

      $('.size-full-select').select2().parent().find('.select2-container').width('100%')
      $('.length-select').select2().parent().find('.select2-container').width('100%')
      $('.steel-pipe-name-select').select2().parent().find('.select2-container').width('100%')

    }

    function initQuotationDetailForm(){
      $.each($('.quotation_detail_table_item'), function(index, element){
        var lengthHiddenFieldValue = $(element).find('.detail-length-text').val()

        $(element).find('.detail-length-text').val(lengthHiddenFieldValue)
      })
    }
// ==================概要部分で使用する関数ここから================================
    // 特記事項を追加（最大6件）
    function addNote(){
      var length = $('#notes').children('.row').length
      row = $('#notes').children('.row:first').clone()
      row.find('#add_note').remove()
      row.find('.control-label').text(`特記事項${length + 1}`)

      row.find('.form-control').attr('name', `over_view[note${length + 1}]`)
      row.find('.form-control').attr('id', `over_view_note${length + 1}`)
      row.find('.form-control').val('')
      removeErrorMessage(row.find('.form-control'))
      row.find('.over-view-note').on('keyup change', function(e){
        replaceCharacter($(e.target))
        validateForm($(e.target), 30)
        setValue($(e.target))
        changeConfirmButtonDisabled()
      })

      $('#notes').append(row)

      var afterLength = $('#notes').children('.row').length
      if (afterLength >= 6) {
        $('#add_note').prop('disabled', true)
        return;
      }
    }

    function setOverViewFormActions(){
      $('#over-view-name').on('keyup change', function(e){
        if( $(e.target).val() != '' && !(/^[^a-zA-Z0-9ｦ-ﾝ]+$/.test($(e.target).val())) ){
          $(e.target).val($(e.target).attr('value'))
        }
        validateForm($(e.target), 10)
        setValue($(e.target))
        changeConfirmButtonDisabled()
      })
      $('#over-view-title').on('keyup change', function(e){
        replaceCharacter($(e.target))
        validateForm($(e.target), 19)
        setValue($(e.target))
        changeConfirmButtonDisabled()
      })
      $('#over-view-preferred-delivery-date').on('keyup change', function(e){
        validateForm($(e.target), 10)
        setValue($(e.target))
        checkDate(e.target)
        changeConfirmButtonDisabled()
      })
      $('.over-view-note').on('keyup change', function(e){
        replaceCharacter($(e.target))
        validateForm($(e.target), 30)
        setValue($(e.target))
        changeConfirmButtonDisabled()
      })
    }

    function setValue(target){
      $(target).attr('value', target.val())
    }
// ==================概要部分で使用する関数終わり================================

// ==================納品先部分で使用する関数ここから================================

    // 住所自動入力のイベントを登録
    function setJpostal(){
      $('#destination_zip1').jpostal({
        postcode : [
          '#destination_zip1',
          '#destination_zip2'
        ],
        address : {
          "#destination_address1" : "%3%4%5"
        }
      })
    }

    // 納入先のラジオボタンを選択した時の処理
    function deliveryDistinationRadioEvent(target){
      value = $(target).val()

      if (value == 'existing') {
        restoreDeliveryDestinationParam()
        $('#delivery-destination-select').attr('disabled', false)
        $('#delivery-destination-form').find('input').prop('disabled', true)
        if ($('#delivery-destination-select').val() !== ''){
          $('#edit-delivery-distination').show()
          $('span.zip_address_text').show()
          deliveryDestinationSelectEvent($('#delivery-destination-select'))
        }
        $.each($('#delivery-destination-form').find('input'), function(index, element){
          // $(element).trigger('keyup')
          removeErrorMessage($(element))
        })
        $('#new-delivery-destination-form-buttons').hide()
        $('#is_editing').val('false')
        changeStatusDeliveryDistinationForm('hide')
      } else {
        tempDeliveryDestinationParams()
        $('#delivery-destination-select').attr('disabled', true)
        $('#edit-delivery-distination').hide()
        $('span.zip_address_text').hide()

        $('#delivery-destination-form').find('input').prop('disabled', false)
        $('#delivery-destination-form').find('input').val('')
        $.each($('#delivery-destination-form').find('input'), function(index, element){
          removeErrorMessage($(element))
        })
        $('#existing-delivery-destination-form-buttons').hide()
        $('#new-delivery-destination-form-buttons').show()
        $('#is_editing').val('true')
        changeStatusDeliveryDistinationForm('show')
      }
      submittableSaveButton()
      changeConfirmButtonDisabled()
    }

    // 納入先を選択した時の処理
    function deliveryDestinationSelectEvent(target){
      id = $(target).val()

      if (id === '') {
        $('#edit-delivery-distination').prop('disabled', true)
        $('#edit-delivery-distination').hide()
        $('span.zip_address_text').hide()
        $('#delivery-destination-form').find('input').val('')
        changeConfirmButtonDisabled()
        return;
      }
      $.ajax({
        url: `/quotation_order/delivery_destination?id=${id}`,
        success: function(html, status, xhr) {
          $('#delivery-destination-form').replaceWith(html)
          initDeliveryDestinationEvent()
          changeConfirmButtonDisabled()
          setRequireFormEvent($('#delivery-destination-form'))
          setJpostal()
          changeConfirmButtonDisabled()
          changeStatusDeliveryDistinationForm('hide')
        },
        error: function(xhr, status, e){
          alert(status);
        }
      });

      $('#edit-delivery-distination').prop('disabled', false)
      $('#edit-delivery-distination').show()
    }

    function changeStatusDeliveryDistinationForm(status){
      if (status === 'hide'){
        $('#delivery-destination-form-fields').hide()
      }else if (status === 'show'){
        $('#delivery-destination-form-fields').show()
      }
    }

    // 納入先の保存のリクエストを行う
    function saveDeliveryDestination(message){
      $.ajax({
        url: '/quotation_order/create_delivery_destination',
        type: 'post',
        data: deliveryDestinationParams(),
        success: function(html, status, xhr) {
          alert(message)
          $('#delivery-destination-form').replaceWith(html)
          initDeliveryDestinationEvent()
          $('#existing-delivery-destination-form-buttons').hide()
          $('#new-delivery-destination-form-buttons').hide()
          $('input[name="new_or_existing"][value="existing"]').prop('checked', true)
          $('input[name="new_or_existing"]').prop('disabled', false)
          $('#edit-delivery-distination').prop('disabled', false)
          setJpostal()
          $('#is_editing').val('false')
          changeConfirmButtonDisabled()
          changeStatusDeliveryDistinationForm('hide')
        },
        error: function(xhr, status, e){
          alert(status);
        }
      });
    }

    function deliveryDestinationParams(){
      return {
        delivery_destination: {
          abbreviation: $('#destination_abbreviation').val(),
          name: $('#destination_name').val(),
          zip1: $('#destination_zip1').val(),
          zip2: $('#destination_zip2').val(),
          address1: $('#destination_address1').val(),
          address2: $('#destination_address2').val(),
          address3: $('#destination_address3').val(),
          tel1: $('#destination_tel1').val(),
          tel2: $('#destination_tel2').val(),
          tel3: $('#destination_tel3').val(),
          fax1: $('#destination_fax1').val(),
          fax2: $('#destination_fax2').val(),
          fax3: $('#destination_fax3').val(),
          delivery_destination_code: $('#delivery_destination_code').val()
        }
      }
    }

    // 納入先を編集ボタンを押下した時の処理を登録
    function setEditDeliveryDistinationButtonEvent(){
      $('#edit-delivery-distination').on('click', function(e){
        e.preventDefault()
        if (get_maintenance_status()){
          window.location.href = MaintenancePath;
          return;
        }

        tempDeliveryDestinationParams()
        submittableSaveButton()
        $('#delivery-destination-form-fields').find('input').prop('disabled', false)
        $('#delivery-destination-select').prop('disabled', true)
        $('input[name="new_or_existing"]').prop('disabled', true)
        $('#edit-delivery-distination').prop('disabled', true)
        $('#existing-delivery-destination-form-buttons').show()
        $('#is_editing').val('true')
        $('span.zip_address_text').hide()

        changeConfirmButtonDisabled()
        changeStatusDeliveryDistinationForm('show')
      })
    }

    function setDeliveryDestinationSelectEvent(){
      $('#delivery-destination-select').on('change', function(e){
        deliveryDestinationSelectEvent(e.target)
      })
    }

    function chancelEditDeliveryDestinationEvent(){
      restoreDeliveryDestinationParam()
      $('#delivery-destination-form-fields').find('input').prop('disabled', true)
      $('#delivery-destination-select').prop('disabled', false)
      $('input[name="new_or_existing"]').prop('disabled', false)
      $('#edit-delivery-distination').prop('disabled', false)
      $('#existing-delivery-destination-form-buttons').hide()
      $('#is_editing').val('false')
      $('span.zip_address_text').show()
      changeConfirmButtonDisabled()
      changeStatusDeliveryDistinationForm('hide')
    }

    function tempDeliveryDestinationParams(){
      abbreviation = $('#destination_abbreviation').val()
      name = $('#destination_name').val()
      zip1 = $('#destination_zip1').val()
      zip2 = $('#destination_zip2').val()
      address1 = $('#destination_address1').val()
      address2 = $('#destination_address2').val()
      address3 = $('#destination_address3').val()
      tel1 = $('#destination_tel1').val()
      tel2 = $('#destination_tel2').val()
      tel3 = $('#destination_tel3').val()
      fax1 = $('#destination_fax1').val()
      fax2 = $('#destination_fax2').val()
      fax3 = $('#destination_fax3').val()
    }

    function restoreDeliveryDestinationParam(){
      $('#destination_abbreviation').val(abbreviation)
      $('#destination_name').val(name)
      $('#destination_zip1').val(zip1)
      $('#destination_zip2').val(zip2)
      $('#destination_address1').val(address1)
      $('#destination_address2').val(address2)
      $('#destination_address3').val(address3)
      $('#destination_tel1').val(tel1)
      $('#destination_tel2').val(tel2)
      $('#destination_tel3').val(tel3)
      $('#destination_fax1').val(fax1)
      $('#destination_fax2').val(fax2)
      $('#destination_fax3').val(fax3)

      $.each($('#delivery-destination-form-fields').find('input'), function(index, element){
        removeErrorMessage($(element))
      })
    }

    //  =========バリデーションここから=================

    function validateForm(target, maxLength, is_required = true){
      if (target.val() == '') {
        removeErrorMessage(target)
      }else if (overCharaLength(target.val(), maxLength)) {
        addErrorMessage(target, '入力した文字が長すぎます。')
      }else if (includeUnusableCharacters(target.val())) {
        addErrorMessage(target, '入力した文字は使用できません。')
      }else {
        removeErrorMessage(target)
      }

      submittableSaveButton()
    }

    // 商品選択「長さ」フォームバリデーション
    function validateNumericLengthForm(target, maxLength){
      if (target.val() == '') {
        removeErrorMessage(target)
      }else if (target.val() > Number($('#selected-steel-pipe-max-length').val())){
        addErrorMessage(target, 'ご指定いただけません。')
      }else if (overCharaLength(target.val(), maxLength)) {
        addErrorMessage(target, '入力した文字が長すぎます。')
      }else if (includeUnusableCharacters(target.val())) {
        addErrorMessage(target, '入力した文字は使用できません。')
      }else if (!isNumeric(target.val()) && target.val().slice(-1) !== '.'){
        addErrorMessage(target, '数値を入力してください。')
      }
      else {
        removeErrorMessage(target)
      }

      submittableSaveButton()
    }

    function isNumeric(char){
      const integerRegex = /^-?\d+$/;
      const decimalRegex = /^-?\d+(\.\d+)?$/;

      return integerRegex.test(char) || decimalRegex.test(char);
    }

    function validateGroupForm(targets){
      var is_length_valid = false
      var is_unusable_character_valid = false
      $.each(targets, function (index, hash) {
        if (hash.is_required && hash.target.val() == '') {
        }else if (overCharaLength(hash.target.val(), hash.maxLength)) {
          is_length_valid = true
        }else if (includeUnusableCharacters(hash.target.val())) {
          is_unusable_character_valid = true
        }
      });

      if (is_length_valid) {
        $.each(targets, function (index, hash) {
          addErrorMessage(hash.target, '入力した文字が長すぎます。')
        });
      }else if (is_unusable_character_valid) {
        $.each(targets, function (index, hash) {
          addErrorMessage(hash.target, '入力した文字は使用できません。')
        });
      }else {
        $.each(targets, function (index, hash) {
          removeErrorMessage(hash.target)
        });
      }

      submittableSaveButton()
    }

    function setDeliveryDestinationValidations(){
      var zip_targets = [{target: $('#destination_zip1'), maxLength: 3, is_required: true}, {target: $('#destination_zip2'), maxLength: 4, is_required: true}]
      var address_targets = [{target: $('#destination_address1'), maxLength: 20, is_required: true}, {target: $('#destination_address2'), maxLength: 10, is_required: true}]
      var tel_targets = [{target: $('#destination_tel1'), maxLength: 5, is_required: true}, {target: $('#destination_tel2'), maxLength: 5, is_required: true}, {target: $('#destination_tel3'), maxLength: 5, is_required: true}]
      var fax_targets = [{target: $('#destination_fax1'), maxLength: 5, is_required: false}, {target: $('#destination_fax2'), maxLength: 5, is_required: false}, {target: $('#destination_fax3'), maxLength: 5, is_required: false}]
      $('#destination_zip1,#destination_zip2,#destination_tel1,#destination_tel2,#destination_tel3,#destination_fax1,#destination_fax2,#destination_fax3').on('input', function(e){
        replaceFullWidthChar($(e.target))
      })

      $('#destination_abbreviation').on('keyup change', function(e){
        validateForm($(e.target), 10)
      })
      $('#destination_name').on('keyup change', function(e){
        replaceCharacter($(e.target))
        validateForm($(e.target), 25)
      })
      $('#destination_zip1').on('keyup change', function(e){
        validateGroupForm(zip_targets)
      })
      $('#destination_zip2').on('keyup change', function(e){
        validateGroupForm(zip_targets)
      })
      $('#destination_address1').on('keyup change', function(e){
        replaceCharacter($(e.target))
        validateGroupForm(address_targets)
      })
      $('#destination_address2').on('keyup change', function(e){
        replaceCharacter($(e.target))
        validateGroupForm(address_targets)
      })
      $('#destination_address3').on('keyup change', function(e){
        replaceCharacter($(e.target))
        validateForm($(e.target), 30)
      })
      $('#destination_tel1').on('keyup change', function(e){
        validateGroupForm(tel_targets)
      })
      $('#destination_tel2').on('keyup change', function(e){
        validateGroupForm(tel_targets)
      })
      $('#destination_tel3').on('keyup change', function(e){
        validateGroupForm(tel_targets)
      })
      $('#destination_fax1').on('keyup change', function(e){
        validateGroupForm(fax_targets)
      })
      $('#destination_fax2').on('keyup change', function(e){
        validateGroupForm(fax_targets)
      })
      $('#destination_fax3').on('keyup change', function(e){
        validateGroupForm(fax_targets)
      })
    }
    //  =========バリデーションここまで=================

    function initDeliveryDestinationEvent(){
      setEditDeliveryDistinationButtonEvent()
      setDeliveryDestinationSelectEvent()
      setDeliveryDestinationValidations()
    }

    function overCharaLength(str, maxLength){
      if (str.length <= maxLength) {
        return false;
      }else{
        return true;
      }
    }

    function addErrorMessage(target, message){
      if (target.parent('.has-error').length == 0) {
        target.wrap('<span class="has-error"></span>');
      }
      var error_message_element = $('<p class="error_message">').text(message);

      var error_message_area = target.closest('.form-group').find('.error_message_area')
      if (error_message_area.contents().length == 0) {
        error_message_area.append(error_message_element);
      }else{
        error_message_area.contents().replaceWith(error_message_element);
      }
    }

    function removeErrorMessage(target){
      var spanElement = target.parent('.has-error');

      // span要素の子要素を取得
      var childElement = spanElement.contents();

      // 子要素をspan要素の親要素に移動
      spanElement.replaceWith(childElement);

      target.closest('.form-group').find('.error_message').remove();
    }

    // TODO使用してはいけない文字を含んでいないかチェックする
    function includeUnusableCharacters(str){
      if (str === '' || str === null) { return false }

      for (var i = 0; i < UnusableCharacters.length; i++) {
        if (str.includes(UnusableCharacters[i])) {
          return true
        }
      }
      return false
    }

    // 半角文字であるかをチェックし、半角文字以外を削除する
    function checkHalfWidthChar(target) {
      value = target.val()
      if (value.match(/[^a-zA-Z0-9]+/)) {
        value = value.replace(/[^a-zA-Z0-9]+/, "");
      }
      target.val(value);
    }

    // 半角文字を全角文字に変換する
    function replaceCharacter(target) {
      value = target.val()
      if (typeof value !== 'string') { return }

      replace_value = String(value).replace(/[!-~]/g, function(all) {
        return String.fromCharCode(all.charCodeAt(0) + 0xFEE0);
      });

      target.val(replace_value)
    };

    // 全角文字を半角文字に変換する
    function replaceFullWidthChar(target) {
      value = target.val()
      if (typeof value !== 'string') { return }

      replace_value = String(value).replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(all) {
        return String.fromCharCode(all.charCodeAt(0) - 0xFEE0);
      });

      target.val(replace_value)
    };

    function submittableSaveButton(){
      targets = $('#delivery-destination-form-fields').find('input')

      is_valid = false
      $.each(targets, function (index, target) {
        if (($(target).data('required') == true && $(target).val() == '') || $(target).closest('.form-group').find('.error_message').length > 0){
          is_valid = true
        }
      });

      var tel_inputs = [$('#destination_tel1'),$('#destination_tel2'),$('#destination_tel3')]
      var fax_inputs = [$('#destination_fax1'),$('#destination_fax2'),$('#destination_fax3')]

      if (!(isAllEmptyValue(tel_inputs) || isAllFilledValue(tel_inputs))) {
        is_valid = true
      }
      if (!(isAllEmptyValue(fax_inputs) || isAllFilledValue(fax_inputs))) {
        is_valid = true
      }

      if (is_valid) {
        $('#save-existing-delivery-destination').attr('disabled', true)
        $('#save-new-delivery-destination').attr('disabled', true)
      }
      else{
        $('#save-existing-delivery-destination').attr('disabled', false)
        $('#save-new-delivery-destination').attr('disabled', false)
      }
    }

    function isAllEmptyValue(inputs){
      return inputs.every(input => input.val() === '')
    }

    function isAllFilledValue(inputs){
      return inputs.every(input => input.val() !== '')
    }
// ==================納入先部分で使用する関数終わり================================

// ==================商品選択で使用する関数ここから================================

// カテゴリ/寸法から検索の鋼管選択モーダルから交換を選んだ際に情報を取得してくる
$('.steel-pipe-form').on('ajax:success', function(event) {
  const [json, status, xhr] = event.detail;
  const contentType = xhr.getResponseHeader("content-type") || "";
  if (contentType.indexOf('html') > -1) {
    alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
    location.href = '/login';
  }
  if (contentType.indexOf('json') > -1) {
    $('#selected-steel-pipe-cuttable').val(json.steel_pipe_is_cuttable)
    setSteelPipeItem(json)
  }
  changeSearchStockButtonDisabled()
  $(this).parents('.modal').modal('hide')
});
$('.steel-pipe-form').on('ajax:error', function(event) {
  const [json, status, xhr] = event.detail;
  alert(status);
});

// /stocks/steel_pipe からのレスポンスを各フォームにセット
function setSteelPipeItem(json) {
  $('.item-name').html(json.item_name);
  $('.item-name-hidden').val(json.item_name);
  $('.size-full-select').html(json.size_fulls);
  $('.length-select').html([]);
  $('.item-name-select').html([]);
  if ($('.size-full-select').parent().find('.selection').length === 0) {
    $('select.select2.size-full-select')
      .select2({placeholder: '選択してください'})
  }
  $('select.select2.size-full-select')
    .val(null).trigger('change.select2');
  if ($('#selected-steel-pipe-cuttable').val() == 'true'){
    $('.length-select-form').hide()
    $('.length-text-form').show()
    $('.length-text').val(null)
    $('.enter_length_text').html('長さ入力可')
  }else{
    $('.length-select-form').show()
    $('.length-text-form').hide()
    $('.enter_length_text').html(null)
    if ($('.length-select').parent().find('.selection').length === 0) {
      $('select.select2.length-select')
      .select2({placeholder: '寸法を選択してください'})
    }
  }
  if ($('#steel-pipe-name-select').parent().find('.selection').length === 0) {
    $('select.select2.steel-pipe-name-select')
      .select2({placeholder: '寸法を選択してください'})
  }
  $('#selected-steel-pipe-type').val(json.steel_pipe_type)
  defineSizefullSelect();
  removeErrorMessage($('.length-text'))

  if (json.is_size_full_search) {
    // 鋼種名セレクトボックスの横幅を合わせる
    let other_select_width = $(json.target_id + ' ' + 'select.select2.size-full-select').parent().find('.selection').width()

    $('#steel-pipe-name-select').show()
    $('select.select2.steel-pipe-name-select').parent().find('.select2-container').width(other_select_width)
    defineSteelPipeNameSelect(json.target_id)
  }else{
    $('#steel-pipe-name-select').hide()
  }
  $('select.select2.steel-pipe-name-select')
    .val(null).select2({placeholder: '寸法を選択してください'})
}

// 寸法のセレクトボックスを変更した際に長さを取得する
function defineSizefullSelect() {
  $('.size-full-select').off('change.with-request');
  $('.size-full-select').on('change.with-request', function(event){

    $.ajax({
      url: '/stocks/steel_pipe_length',
      type: 'post',
      data: {
        item_name_text: $('.item-name-hidden').val(),
        size_full_text: $(this).val(),
        steel_pipe_type: $('#selected-steel-pipe-type').val(),
      },
      success: function(json, status, xhr) {
        var contentType = xhr.getResponseHeader("content-type") || "";
        if (contentType.indexOf('html') > -1) {
          alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
          location.href = '/login';
        }
        if (contentType.indexOf('json') > -1) {
          if ($('.item-name-hidden').val() == '寸法から選択'){
            if (json.is_cuttable){
              $('.length-select-form').hide()
              $('.length-text-form').show()
              $('.length-text').val(null)
              $('.enter_length_text').html('長さ入力可')
            }else{
              $('.length-select-form').show()
              $('.length-text-form').hide()
              $('.enter_length_text').html(null)
              if ($('.length-select').parent().find('.selection').length === 0) {
                $('select.select2.length-select')
                .select2({placeholder: '寸法を選択してください'})
              }
            }
          }
          if (json.is_cuttable){
            var length_text_select = $('.length-text-select')
            length_text_select.empty()
            length_text_select.append(json.lengths)
            if (json.single_flg) {
              $('.length-text').val(json.length)
              $('.length-text').trigger('change.with-request')
            }else {
              $('.length-text').val(null)
            }
          }else{
            $('.length-select').html(json.lengths);
            if (json.single_flg) {
              $('select.select2.length-select').select2({placeholder: '選択してください'})
              $('select.select2.length-select').val(json.length).trigger('change.select2');
              $('select.select2.item-name-select').select2({placeholder: '長さを選択してください'})
              $('.length-select').trigger('change.with-request')
            }else {
              $('select.select2.length-select').select2({placeholder: '選択してください'})
              $('select.select2.length-select').val(null).trigger('change.select2');
              $('select.select2.item-name-select').select2({placeholder: '長さを選択してください'})
            }
          }
        }
        if($('#selected_text_length_val').length) {
          $('.length-text').val($('#selected_text_length_val').val())
          $('#selected_text_length_val').remove();
        }
        if($('#selected_length_val').length) {
          $('select.select2.length-select').val($('#selected_length_val').val()).trigger('change.select2');
          $('#selected_length_val').remove();
        }
        $('.steel-pipe-name-select').html(null)
        $('select.select2.steel-pipe-name-select').select2({placeholder: '選択してください'})
        $('#selected-steel-pipe-max-length').val(json.steel_pipe_max_length)


        changeSearchStockButtonDisabled()
      },
      error: function(xhr, status, e) {
        alert(status);
      }
    });
  });
}

// 長さのセレクトボックスを変更した際に鋼種名を取得する（寸法から選択時のみ）
function defineSteelPipeNameSelect(target_id) {
  $('.length-select, .length-text').off('change.with-request');
  $('.length-select, .length-text').on('change.with-request', function(event){
    let is_none = $('#steel-pipe-name-select').css('display') === "none";

    // 寸法から検索ではない場合はAPIリクエストを投げない
    if (is_none) { return; }

    $.ajax({
      url: '/stocks/steel_pipe_name',
      type: 'post',
      data: {
        size_full_text: $('.size-full-select').val(),
        length_text: $(this).val(),
        steel_pipe_type: $('#selected-steel-pipe-type').val()
      },
      success: function(json, status, xhr) {
        var contentType = xhr.getResponseHeader("content-type") || "";
        if (contentType.indexOf('html') > -1) {
          alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
          location.href = '/login';
        }
        if (contentType.indexOf('json') > -1) {
          $('.steel-pipe-name-select')
            .html(json.steel_pipe_names);
          if (json.single_flg) {
            $('select.select2.steel-pipe-name-select')
              .select2({placeholder: '選択してください'})
            $('select.select2.steel-pipe-name-select')
              .val(json.steel_pipe_name).trigger('change.select2');
          }else {
            $('select.select2.steel-pipe-name-select')
              .select2({placeholder: '選択してください'})
            $('select.select2.steel-pipe-name-select')
              .val(null).trigger('change.select2');
          }
        }
        changeSearchStockButtonDisabled()
      },
      error: function(xhr, status, e) {
        alert(status);
      }
    });
  });
  $('.steel-pipe-name-select').on('change.with-request', function(event){
    changeSearchStockButtonDisabled()
  });
}

// 照会ボタンを押した際に在庫を検索する
function requestStockSearch(){
  errors = stockSearchErrorMessage()

  if (errors.length > 0) {
    alert(errors.join("\n"))
    return false
  }

  $.ajax({
    url: '/quotation_order/stock_search',
    type: 'post',
    data: {
      stock_search:{
        item_name: $('.item-name-hidden').val(),
        size_full: $('.size-full-select').val(),
        length: getLengthValue(),
        pipe_num: $('#pipe_num').val(),
        steel_pipe_name: $('.steel-pipe-name-select').val()
      }
    },
    success: function(json, status, xhr) {
      const contentType = xhr.getResponseHeader("content-type") || "";
        if (contentType.indexOf('html') > -1) {
          alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
          location.href = '/login';
        }
        if (contentType.indexOf('json') > -1) {
          if (json.error) {
            alert(json.error);
            return false;
          }
          $('#search_result').html(json.result);
      }
    },
    error: function(xhr, status, e) {
      alert(status);
    }
  });
}

// 商品選択のフォームに必須情報が入力されているかのチェック
function stockSearchErrorMessage(){
  errors = []

  $('.item-name-hidden').val() ? '' : errors.push('商品名を入力してください')
  $('.size-full-select').val() ? '' : errors.push('寸法を入力してください')
  getLengthValue() ? '' : errors.push('長さを入力してください')
  if ($('.length-text-form').css('display') != 'none'){
    overCharaLength(getLengthValue() || '', 6) ? errors.push('長さ:入力した文字が長すぎます。') : ''
    includeUnusableCharacters(getLengthValue()) ? errors.push('長さ:入力した文字は使用できません。') : ''
    !isNumeric($('.length-text').val()) && $('.length-text').val().slice(-1) !== '.' ? errors.push('長さ:数値を入力してください。') : ''
    $('.length-text').val() > Number($('#selected-steel-pipe-max-length').val()) ? errors.push('長さ:ご指定いただけません。') : ''
  }
  $('#pipe_num').val() ? '' : errors.push('員数を入力してください')
  overCharaLength($('#pipe_num').val() || '', 6) ? errors.push('員数:入力した文字が長すぎます。') : ''
  includeUnusableCharacters($('#pipe_num').val()) ? errors.push('員数:入力した文字は使用できません。') : ''
  if ($('#steel-pipe-name-select').css('display') != 'none'){
    $('.steel-pipe-name-select').val() ? '' : errors.push('鋼種名を入力してください')
  }
  return errors
}

function changeSearchStockButtonDisabled(){
  errors = stockSearchErrorMessage()

  if (errors.length > 0) {
    $("#search_stock").prop("disabled", true);
    $('.add-quotation-detail').prop("disabled", true);
  }else{
    $("#search_stock").prop("disabled", false);
    $('.add-quotation-detail').prop("disabled", false);
    changeAddQuotationDetailButtonDisabled();
  }
}

function changeUpdateCopyButtonDisabled(){
  $('.detail-length-text').each(function(){
    if($(this).parent('.has-error').length > 0) {
      $(this).closest('.quotation_detail_table_item').find('.update-quotation-detail').prop('disabled', true)
      $(this).closest('.quotation_detail_table_item').find('.copy-quotation-detail').prop('disabled', true)
    }else{
      $(this).closest('.quotation_detail_table_item').find('.update-quotation-detail').prop('disabled', false)
      if ($('.quotation_detail_table_item').length < 30){
        $(this).closest('.quotation_detail_table_item').find('.copy-quotation-detail').prop('disabled', false)
      }
    }
  })
}

// 表から選択の鋼管モーダルから鋼管を選んだ際の処理
$('.list-select-steel-pipe-form').on('ajax:success', function(event) {
  const [json, status, xhr] = event.detail;
  const contentType = xhr.getResponseHeader("content-type") || "";
  if (contentType.indexOf('html') > -1) {
    alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
    location.href = '/login';
  }
  if (contentType.indexOf('json') > -1) {
    // floatThead設定があれば解除
    if($('#stock-list').data('floatThead-attached')){
      $('#stock-list').floatThead('destroy');
    }

    $('#stock-list').html(json.list)
    $('#selected-steel-pipe-type').val(json.steel_pipe_type)
    $('#list-select').find('.item-name').text(json.item_text)
    $('#stock-list').find('.select-steel-pipe').on('click', function(e){
      e.preventDefault()
      selectListSteelPipe($(e.target))
    })
    fixedItemSelectTableHeader()
  }
  $(this).parents('.modal').modal('hide')
});
$('.list-select-steel-pipe-form').on('ajax:error', function(event) {
  const [json, status, xhr] = event.detail;
  alert(status);
});

// 表から選択の要素の選択ボタンを押下した際の処理
function selectListSteelPipe(target){
  $('#selected-steel-pipe-type').val(target.data('steel-pipe-type'))
  $('#selected-steel-pipe-cuttable').val(target.data('is-cuttable'))
  $('#selected-steel-pipe-max-length').val(target.data('max-length'))
  setSteelPipeForm(target)
  target.parents('.modal').modal('hide')
}

function setSteelPipeForm(target){
  $('.item-name').html(target.data('item-name'))
  $('.item-name-hidden').val(target.data('item-name'))
  // 寸法セレクトの変更
  var size_select = $('.size-full-select')
  size_select.empty()
  size_select.append($('<option>').html(target.data('size-full')).val(target.data('size-full')))
  size_select.val(target.data('size-full'))
  removeErrorMessage($('.length-text'))

  // 長さセレクトの変更
  if($('#selected-steel-pipe-cuttable').val() == "true"){
    $('.length-select-form').hide()
    $('.length-text-form').show()
    $('.length-text').val(target.data('length'))
    $('.enter_length_text').html('長さ入力可')
  }else{
    $('.length-select-form').show()
    $('.length-text-form').hide()
    $('.enter_length_text').html(null)
    var length_select = $('.length-select')
    length_select.empty()
    length_select.append($('<option>').html(target.data('length')).val(target.data('length')))
    length_select.val(target.data('length'))
  }
}

// 商品選択のフォームのクリアボタンを押下した際の処理
function itemSearchFealdClear(){
  $('.select-item-name-text').text('')
  $('.size-full-select').html([])
  $('.length-select').html([])
  $('.length-text').val('')
  $('.steel-pipe-name-select').html([])
  $('#pipe_num').val('')
  $('#steel-pipe-name-select').hide()
}

// 長さのフォームが二種類あるので値を取得する関数を用意
function getLengthValue(){
  var value = $('.search-length:visible').first().val()

  return value;
}

function fixedItemSelectTableHeader(){
  let $table = $('#stock-list');

  $table.floatThead({
    scrollContainer: function ($table) {
      return $table.closest('.table-responsive'); // スクロールコンテナを指定
    }
  });
}
// ==================商品選択で使用する関数終わり================================

// ==================明細で使用する関数ここから================================

function addQuotationDetail(){
  errors = stockSearchErrorMessage()

  if (errors.length > 0) {
    alert(errors.join("\n"))
    return false
  }

  $.ajax({
    url: '/quotation_order/add_quotation_order_detail',
    type: 'post',
    data: {
      stock_search:{
        item_name: $('.item-name-hidden').val(),
        size_full: $('.size-full-select').val(),
        length: getLengthValue(),
        pipe_num: $('#pipe_num').val(),
        steel_pipe_name: $('.steel-pipe-name-select').val()
      },
      steel_pipe_type: $('#selected-steel-pipe-type').val(),
      row_num: $('.quotation_detail_table_item').length + 1,
      with_milsheet: $('#with_milsheet').prop('checked')
    },
    success: function(json, status, xhr) {
      const contentType = xhr.getResponseHeader("content-type") || "";
        if (contentType.indexOf('html') > -1) {
          alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
          location.href = '/login';
        }
        if (contentType.indexOf('json') > -1) {
          if (json.error) {
            alert(json.error);
            return false;
          }
          if ($('#quotation_detail_table_body').children().length < 30) {
            $('#quotation_detail_table_body').append(json.item);

            var addedElement = $('#quotation_detail_table_body').children().last();
            setQuotationDetailsActions(addedElement)

            changeAddQuotationDetailButtonDisabled()
            changeConfirmButtonDisabled()
            setMaintenanceToolTip()
            defineSizefullSelectDetail();
            applyQotationDetailRowNumber();
          }
      }
    },
    error: function(xhr, status, e) {
      alert(status);
    }
  });
}

function deleteQuotationDetail(target){
  target.closest('.quotation_detail_table_item').remove()
  changeAddQuotationDetailButtonDisabled()
  applyQotationDetailRowNumber()
  changeConfirmButtonDisabled()
  changeSearchStockButtonDisabled()
  setMaintenanceToolTip()
}

function copyQuotationDetail(target){
  origin = target.closest('.quotation_detail_table_item')
  copy = target.closest('.quotation_detail_table_item').clone(true)

  copy.find('.detail-size-full-select').val(origin.find('.detail-size-full-select').val())
  copy.find('.length').val(origin.find('.length').val())
  copy.find('.pipe-num').val(origin.find('.pipe-num').val())
  $('#quotation_detail_table_body').append(copy);
  changeAddQuotationDetailButtonDisabled()
  applyQotationDetailRowNumber()
  changeConfirmButtonDisabled()
  changeSearchStockButtonDisabled()
  setMaintenanceToolTip()
}

function applyQotationDetailRowNumber() {
  $('.row_num').each(function(i){
    $(this).text(i+1);
    $(this).parent().find('.row_num_hidden').val(i+1);
    $(this).parent().find('.detail-length-text').attr('list', `detail-length-text-select_${i+1}`)
    $(this).parent().find('.detail-length-text-select').attr('id', `detail-length-text-select_${i+1}`)

    $(this).parent().find('.require_input').each(function(){
      param_name = $(this).data('param-name')
      $(this).attr('name', `[quotation_order_details][${i+1}][${param_name}]`)
    })
  });
  changeAddQuotationDetailButtonDisabled()
}

function allChangeQuotationDetailCheck(value){
  $('.select_check').each(function(){
    $(this).prop('checked', value)
  })
}

function deleteSelectedDetails(){
  $('.select_check').each(function(){
    if ($(this).prop('checked')) {
      $(this).closest('.quotation_detail_table_item').remove()
    }
  })
  applyQotationDetailRowNumber()
  changeConfirmButtonDisabled()
  changeSearchStockButtonDisabled()
  setMaintenanceToolTip()
}

function deleteAllDetails(){
  $('.quotation_detail_table_item').remove()
  $('#delete-all-detail').prop('disabled', true)
  changeConfirmButtonDisabled()
}

$('#delete-all-detail').on('click', function(){
  if (get_maintenance_status()){
    window.location.href = MaintenancePath;
    return false;
  }

  if ($('.quotation_detail_table_item').length == 0) {
    alert('明細がありません。');
    return false;
  }
  $('#delete-modal').modal();
  return false;
});

$('#delete_all_details').on('click', function(){
  deleteAllDetails();
  changeSearchStockButtonDisabled()
  setMaintenanceToolTip()
  $('#delete-modal').modal('hide');
})


function updateQuotationDetail(e){
  var item = $(e.target).closest('.quotation_detail_table_item')

  var row_num = item.find('.row_num_hidden').val()
  var category_text = item.find('.category_text_hidden').val()
  var item_name = item.find('.item_name_hidden').val()
  var size_full = item.find('.detail-size-full-select option:selected').text()
  var length = item.find('.length').val()
  var pipe_num = item.find('.pipe-num').val()
  var with_milsheet = item.find('.with_milsheet').prop('checked')

  $.ajax({
    url: '/quotation_order/update_quotation_order_detail',
    type: 'post',
    data: {
      stock_search:{
        item_name: item_name,
        size_full: size_full,
        length: length,
        pipe_num: pipe_num,
      },
      steel_pipe_type: item.find('.steel-pipe-type-hidden').val(),
      row_num: row_num,
      with_milsheet: with_milsheet
    },
    success: function(json, status, xhr) {
      const contentType = xhr.getResponseHeader("content-type") || "";
        if (contentType.indexOf('html') > -1) {
          alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
          location.href = '/login';
        }
        if (contentType.indexOf('json') > -1) {
          if (json.error) {
            alert(json.error);
            return false;
          }
          if (Number.isInteger(json.total_weight)) {
            total_weight = json.total_weight.toFixed(1)
          }else{
            total_weight = json.total_weight
          }
          item.find('.total_weight').text(total_weight);
          item.find('.stock_condition').text(json.stock_condition);
          item.find('.unit_price').text(json.unit_price);
      }
    },
    error: function(xhr, status, e) {
      alert(status);
    }
  });
}

function changeAddQuotationDetailButtonDisabled(){
  if($('.quotation_detail_table_item').length >= 30){
    $('.add-quotation-detail').prop('disabled', true)
    $('.copy-quotation-detail').prop('disabled', true)
  }else{
    $('.add-quotation-detail').prop('disabled', false)
    $('.copy-quotation-detail').prop('disabled', false)
  }
}

function setQuotationDetailsActions(details){
  details.each(function(index, detail){
    $(detail).find('.delete-quotation-detail').on('click', function(e){
      e.preventDefault()
      if (get_maintenance_status()){
        window.location.href = MaintenancePath;
        return;
      }

      deleteQuotationDetail($(e.target))
    })
    $(detail).find('.update-quotation-detail').on('click', function(e){
      e.preventDefault()
      if (get_maintenance_status()){
        window.location.href = MaintenancePath;
        return;
      }

      updateQuotationDetail(e)
    })
    $(detail).find('.copy-quotation-detail').on('click', function(e){
      e.preventDefault()
      if (get_maintenance_status()){
        window.location.href = MaintenancePath;
        return;
      }

      copyQuotationDetail($(e.target))
    })
    $(detail).find('.select_check').on('change', function(e){
      e.preventDefault()
      changeDeleteDetailsButtonDisabled()
    })
    $(detail).find('.pipe-num').on('input', function(e){
      if (parseInt(e.target.value) > parseInt(e.target.max)) {
        e.target.value = e.target.max;
      }

      $(e.target).attr('value', e.target.value)
      changeConfirmButtonDisabled()
    })
    $(detail).find('.with_milsheet').on('change', function(e){
      e.preventDefault()
      $(e.target).attr('checked', $(e.target).prop('checked'))
    })
    $(detail).find('.detail-length-text').on('input', function(e){
      if (!isNumeric($(e.target).val())){
        $(e.target).val($(e.target).val().replace(/[^0-9.]/g,''))
      }
      if($(e.target).val() > Number($(e.target).closest('.quotation_detail_table_item').find('.max_length-hidden').val())){
        if ($(e.target).parent('.has-error').length == 0) {
          $(e.target).wrap('<span class="has-error"></span>');
          $(e.target).closest('.quotation_detail_table_item').find('.update-quotation-detail').prop('disabled', true)
          $(e.target).closest('.quotation_detail_table_item').find('.copy-quotation-detail').prop('disabled', true)
        }
      }else{
        if ($(e.target).parent('.has-error').length > 0){
          $(e.target).unwrap();
          $(e.target).closest('.quotation_detail_table_item').find('.update-quotation-detail').prop('disabled', false)
          if ($('.quotation_detail_table_item').length < 30){
            $(e.target).closest('.quotation_detail_table_item').find('.copy-quotation-detail').prop('disabled', false)
          }
        }
      }
      setLengthErrorTooltip($(e.target))
      $(e.target).attr('value', e.target.value)
      changeConfirmButtonDisabled()
    })
    $(detail).find('.detail-size-full-select').on('change', function(e){
      $(e.target).attr('value', $(e.target).val())
      $(e.target).closest('.quotation_detail_table_item').find('.size-full-code-hidden').val($(e.target).val())
      $(e.target).find('option').removeAttr('selected')
      $(e.target).find(`option[value="${$(e.target).val()}"]`).attr('selected', true)
    })
    $(detail).find('.detail-length-select').on('change', function(e){
      $(e.target).attr('value', $(e.target).val())
      $(e.target).closest('.quotation_detail_table_item').find('.detail-length-select').val($(e.target).val())
      $(e.target).find('option').removeAttr('selected')
      $(e.target).find(`option[value="${$(e.target).val()}"]`).attr('selected', true)
    })
  })
}

function changeDeleteDetailsButtonDisabled(){
  if ($('.select_check:checked').length > 0) {
    $('#delete-selected-detail').prop('disabled', false)
  }else{
    $('#delete-selected-detail').prop('disabled', true)
  }

  if ($('.quotation_detail_table_item').length > 0) {
    $('#delete-all-detail').prop('disabled', false)
  }else{
    $('#delete-all-detail').prop('disabled', true)
  }
}

function createDetailTableObserver(){
  if (document.getElementById('quotation_detail_table_body') == null) { return }
  const callBack = function(mutationsList, observer) {
    changeDeleteDetailsButtonDisabled()
  }

  const observer = new MutationObserver(callBack);

  const config = { attributes: true, childList: true, subtree: true };

  observer.observe(document.getElementById('quotation_detail_table_body'), config);
}

// 寸法のセレクトボックスを変更した際に長さを取得する
function defineSizefullSelectDetail() {
  $('.detail-size-full-select').off('change.with-request');
  $('.detail-size-full-select').on('change.with-request', function(event){
    let $item_row = $(this).closest('.quotation_detail_table_item')

    $.ajax({
      url: '/stocks/steel_pipe_length',
      type: 'post',
      data: {
        item_name_text: $item_row.find('.item_name_hidden').val(),
        size_full_text: $("option:selected", this).text(),
        steel_pipe_type: $item_row.find('.steel-pipe-type-hidden').val(),
      },
      success: function(json, status, xhr) {
        var contentType = xhr.getResponseHeader("content-type") || "";

        if (contentType.indexOf('html') > -1) {
          alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
          location.href = '/login';
        }
        if (contentType.indexOf('json') > -1) {
          let $detail_length_select = $item_row.find('.detail-length-select')
          let $detail_length_text = $item_row.find('.detail-length-text')
          let $is_cuttable = $item_row.find('.is_cuttable-hidden')
          if($detail_length_text.parent('.has-error').length > 0) {
            $detail_length_text.unwrap();
            $detail_length_text.closest('.quotation_detail_table_item').find('.update-quotation-detail').prop('disabled', false)
            $detail_length_text.closest('.quotation_detail_table_item').find('.copy-quotation-detail').prop('disabled', false)
            $detail_length_text.tooltip('destroy')
          }
          if ($is_cuttable.val() == "true"){
            var length_text_select = $item_row.find('.detail-length-text-select')
            length_text_select.empty()
            length_text_select.html(json.lengths)
            if (json.single_flg) {
              $detail_length_text.val(json.length)
            }else{
              $detail_length_text.val(null)
            }
            $item_row.find('.max_length-hidden').val(json.steel_pipe_max_length)
          }else{
            $detail_length_select.html(json.lengths);
            if (json.single_flg) {
              $detail_length_select.val(json.length);
              $detail_length_select.trigger('change.with-request')
            }else {
              $detail_length_select.trigger('change.with-request')
            }
          }
        }
      },
      error: function(xhr, status, e) {
        alert(status);
      }
    });
  });
}

function setLengthErrorTooltip(target){
  if (target.parent('.has-error').length > 0) {
    target.tooltip({
      title: `ご指定いただけません`,
      placement: 'right'
    })
  }else{
    target.tooltip('destroy')
  }
}


// ==================明細で使用する関数終わり================================
    initForm()
    initToolTips()
    changeSearchStockButtonDisabled()
    setAccordionIconAnimation()
    getMaintenanceStatus()
    setMaintenanceToolTip()
  }
});
